.maininput__container {
  position: relative;
  width: 100%;
}

.input__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.displayname__input {
  width: 300px;
  height: 32px;
  padding: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  border-radius: 14px 0px 0px 14px;
  border: none;
}

.displayname__input.score {
  border-radius: 14px;
}

.displayname__input:focus {
  outline: none;
}

.submit__button {
  height: 48px;
  padding: 8px;
  border-radius: 0 14px 14px 0;
  border: none;
  background-color: rgb(172, 172, 172);
  cursor: pointer;
}

.submission__list {
  overflow-y: scroll;
  padding: 0 8px;
  height: 350px;
}
.scoreBoard {
  overflow-y: scroll;
  height: 500px;
}

.submission-score-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
}

@media (max-width: 500px) {
  .input__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .submission__list {
    width: 95%;
    margin: 0 auto;
  }

  .displayname__input {
    width: 100%;
  }

  .displayname__input.score {
    border-radius: 14px;
    width: 100%;
  }

  .scoreBoard {
    margin-top: 40px;
  }
}
