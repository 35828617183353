.Clock {
  width: 320px;
  height: 320px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  border: #a83f02 solid 10px;
  color: green;
}

/* media queries */
@media (max-width: 500px) {
  .Clock {
    width: 250px;
    height: 250px;
    font-size: 1rem;
  }
}
