.list__component {
  width: 320px;
  height: 32px;
  padding: 8px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  margin: 8px 0;
}

.time-4-20 {
  color: red;
}

.listcomponent__name {
  color: green;
}

@media (max-width: 500px) {
  .list__component {
    width: 90%;
  }
}
